const autoNgTemplateLoaderTemplate1 = require('./BEFeatureIndicator.html');

import controller from './BEFeatureIndicator.controller';

import './BEFeatureIndicator.css';

export const beFeatureIndicatorAngular = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    feature: '<',
  },
  transclude: true,
};

export { BEFeatureIndicator } from './BEFeatureIndicator';
