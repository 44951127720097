const autoNgTemplateLoaderTemplate1 = require('./web-editor-form.html');

import controller from './web-editor-form.controller.js';

export const webEditorForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,

  bindings: {
    identifier: '@',
    placeholder: '@',
    yml: '<',
    value: '<',
    onChange: '<',
  },

  transclude: {
    description: '?editorDescription',
  },
};
