const autoNgTemplateLoaderTemplate1 = require('./kubernetes-sidebar.html');

import angular from 'angular';

angular.module('portainer.kubernetes').component('kubernetesSidebar', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointId: '<',
    isSidebarOpen: '<',
    adminAccess: '<',
  },
});
